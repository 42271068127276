
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class ResponsiveImage extends Vue {
  @Prop({
    type: [Object, String],
    required: true,
  })
  readonly src!: { [key: `${number}x`]: string } | string;

  container?: HTMLElement | null = null;

  get srcKeys() {
    if (typeof this.src !== "string") {
      const src = this.src;
      return Object.keys(src) as unknown as Array<
        keyof Exclude<typeof src, "string">
      >;
    }

    return null;
  }

  get srcSet() {
    if (typeof this.src !== "string") {
      if (this.srcKeys?.length) {
        const src = this.src;
        return this.srcKeys.map((key) => `${src[key]} ${key}`);
      }
    } else {
      return [
        this.getImageProcessorUrl(this.src, 1),
        this.getImageProcessorUrl(this.src, 2) + " 2x",
        this.getImageProcessorUrl(this.src, 3) + " 3x",
      ];
    }

    return [];
  }

  get defaultSrc() {
    if (typeof this.src !== "string") {
      if (this.srcKeys?.length) {
        return this.src[this.srcKeys[0]];
      }
    } else {
      return this.getImageProcessorUrl(this.src);
    }

    return null;
  }

  getImageProcessorUrl(url: string, density = 1) {
    const envs = ["test", "stage", "live"];
    const map = envs.reduce<Record<string, string>>((map, env) => {
      const imgProxyUrl = `https://imgproxy-${env}.rtl.hu/`;
      map[`https://storage-${env}.rtl.hu/`] = imgProxyUrl;
      map[`https://rtlhd-storage-${env}.azureedge.net/`] = imgProxyUrl;
      map[`https://rtldigitalhu${env}cdn.azureedge.net/`] = imgProxyUrl;
      return map;
    }, {});

    let width = 0;
    let height = 0;

    const container = this.container;
    if (container) {
      // TODO: get image rendering size from parent container
      // const { paddingLeft, paddingRight } = getComputedStyle(container);
      // const paddingH = parseFloat(paddingLeft) + parseFloat(paddingRight);
      // const width = container.clientWidth - paddingH;
      // const height = this.imgEl.height;
    }
    const resize = `pr:sharp/rs:fill:${width}:${height}/dpr:${density}/g:ce/`;
    const format = "@webp";

    for (const [key, val] of Object.entries(map)) {
      if (url.startsWith(key)) {
        const uri = url.substring(key.length);
        url = val + resize + "plain/abs://" + uri + format;
        break;
      }
    }

    return url;
  }

  mounted() {
    this.$nextTick(() => {
      this.container = this.$el?.parentElement;
    });
  }
}
