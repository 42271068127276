import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "button-group" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["name", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cta_button = _resolveComponent("cta-button")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "button-group__anim" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableOptions, (item, i) => {
          return (_openBlock(), _createElementBlock("label", {
            class: _normalizeClass(["button-group__item", {
          'button-group__item--first': i === 0,
          'button-group__item--last': i === _ctx.availableOptions.length - 1,
        }]),
            key: item.value,
            onClick: ($event: any) => (item.value === _ctx.model ? _ctx.reset($event) : null)
          }, [
            _withDirectives(_createElementVNode("input", {
              class: "button-group__input",
              type: "radio",
              name: _ctx.name,
              value: item.value,
              disabled: _ctx.readonly,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
            }, null, 8, _hoisted_3), [
              [_vModelRadio, _ctx.model]
            ]),
            _createVNode(_component_cta_button, {
              is: "span",
              class: "button-group__button",
              theme: item.theme,
              selected: item.value === _ctx.model,
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["theme", "selected"])
          ], 10, _hoisted_2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}