
import { Model, Options, Prop, Vue } from "vue-property-decorator";
import { VoteSubject as VoteSubjectType } from "@/api";
import { ButtonGroup } from "@/views/components";
import VoteSubject from "./VoteSubject.vue";

@Options({
  components: {
    ButtonGroup,
    VoteSubject,
  },
})
export default class VoteAnswer extends Vue {
  @Model("modelValue", {
    type: Boolean,
    default: null,
  })
  readonly model!: boolean | null;

  @Prop({
    type: String,
    default: "yesno",
  })
  readonly type!: "battle" | "yesno" | "balance";

  @Prop({
    type: Object,
    required: true,
  })
  readonly subject!: VoteSubjectType;

  @Prop({
    type: String,
    default: "",
  })
  readonly name!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly inactive!: boolean;

  get config() {
    return {
      emptyValue: null,
      trueValue: true,
      falseValue: false,
    };
  }

  get isBattle() {
    return this.type === "battle";
  }

  getItemState(value?: boolean | null) {
    switch (value) {
      case this.config.falseValue:
        return "no";
      case this.config.trueValue:
        return "yes";
    }
  }
}
