
import { Options, Prop, Vue } from "vue-property-decorator";
import { Vote } from "@/models";
import { BlockLink, Headline, ResponsiveImage } from "@/views/components";

@Options({
  components: {
    BlockLink,
    Headline,
    ResponsiveImage,
  },
})
export default class VoteCard extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  readonly vote!: Vote;

  get image() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const placeholder = require("@ui/assets/img/rtl_placeholder_image.svg");
    return this.vote.main.image ?? placeholder;
  }
}
