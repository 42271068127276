import { reactive } from "vue";
import { Options, Vue, Watch } from "vue-property-decorator";
import { Vote } from "@/models";
import { VoteListPayload, VOTE_NAMESPACE, VOTE_ACTION } from "@/store";
import { Fetcher } from "@/utils";

@Options({})
export default class VoteListFetcher extends Vue {
  voteListRefreshed = false;

  get votes() {
    return this.voteListFetcher.list;
  }

  get voteListFetcher() {
    const fetcher = new Fetcher((payload: VoteListPayload) => {
      return this.$store.dispatch(
        `${VOTE_NAMESPACE}/${VOTE_ACTION.FETCH_LIST}`,
        payload
      );
    }, Vote);

    return reactive(fetcher);
  }

  get voteListPayload() {
    return {};
  }

  @Watch("voteListPayload", { deep: true })
  async handleVoteList() {
    return this.fetchVoteList();
  }

  async fetchVoteList(cached = false) {
    return await this.voteListFetcher.run({
      data: this.voteListPayload,
      cached,
    });
  }

  async refreshVoteList() {
    await this.fetchVoteList(false);

    this.voteListRefreshed = true;
    setTimeout(() => {
      this.voteListRefreshed = false;
    }, 1000);
  }

  async visibilityVoteListHandler() {
    if (!document?.hidden) {
      await this.fetchVoteList(false);
    }
  }

  serverPrefetch() {
    return this.fetchVoteList();
  }

  beforeMount() {
    return this.fetchVoteList();
  }

  mounted() {
    document?.addEventListener("visibilitychange", () => {
      this.visibilityVoteListHandler();
    });
  }

  beforeUnmount() {
    document?.removeEventListener("visibilitychange", () => {
      this.visibilityVoteListHandler();
    });
  }
}
