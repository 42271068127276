import { createStore as createVuexStore } from "vuex";
import {
  cachePlugin,
  createApiModule,
  createContentModule,
  createEnvModule,
  createPageModule,
  createUserModule,
  createRssModule,
  HttpError,
  API_NAMESPACE,
  CONTENT_NAMESPACE,
  ENV_NAMESPACE,
  PAGE_NAMESPACE,
  USER_NAMESPACE,
  RSS_NAMESPACE,
} from "@rtl/ui";
import { createVoteModule, VOTE_NAMESPACE } from "./modules";

export type AppState = Record<string, never>;

export function createStore(preserveState = false) {
  return createVuexStore<AppState>({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
      [ENV_NAMESPACE]: createEnvModule<AppState>(false),
      [API_NAMESPACE]: createApiModule<AppState>(false),
      [PAGE_NAMESPACE]: createPageModule<AppState>(true),
      [CONTENT_NAMESPACE]: createContentModule<AppState>(true),
      [VOTE_NAMESPACE]: createVoteModule<AppState>(true),
      [USER_NAMESPACE]: createUserModule<AppState>(true),
      [RSS_NAMESPACE]: createRssModule<AppState>(true),
    },
    plugins: [
      cachePlugin({
        preserveState,
        filter: (type) => {
          return [PAGE_NAMESPACE, CONTENT_NAMESPACE].some((ns) => {
            return type.startsWith(`${ns}/`);
          });
        },
        error: (error) => {
          switch (error.name) {
            case "HttpError":
              return new HttpError(error.statusCode);
          }
          return error;
        },
      }),
    ],
  });
}
