
import { Model, Options, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep, isEqual, merge } from "lodash";
import { VoteSubject as VoteSubjectType } from "@/api";
import { Container, Headline, TextContent } from "@/views/components";
import VoteAnswer from "./VoteAnswer.vue";

@Options({
  components: {
    Container,
    Headline,
    TextContent,
    VoteAnswer,
  },
  emits: ["update:selectedSubject"],
})
export default class VoteQuestion extends Vue {
  @Model("modelValue", {
    type: Object,
    default: () => ({}),
  })
  readonly model!: Record<string, boolean | null>;

  @Prop({
    type: Object,
    default: () => ({}),
  })
  readonly persistedData!: Record<string, boolean | null>;

  @Prop({
    type: String,
    required: true,
  })
  readonly title!: string;

  @Prop({
    type: String,
  })
  readonly text: string | unknown;

  @Prop({
    type: String,
    default: "yesno",
  })
  readonly type!: "battle" | "yesno" | "balance";

  @Prop({
    type: Number,
    default: 0,
  })
  readonly min!: number;

  @Prop({
    type: Number,
    default: 0,
  })
  readonly max!: number;

  @Prop({
    type: Array,
    required: true,
  })
  readonly subjects!: Array<VoteSubjectType>;

  @Prop({
    type: String,
    default: "",
  })
  readonly name!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly modifiable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly selectable!: boolean;

  @Prop({
    type: Object,
    default: null,
  })
  readonly selectedSubject!: VoteSubjectType;

  emptyValue = null;
  value: Record<string, boolean | null> = {};
  selection: VoteSubjectType | null = null;

  get count() {
    const counter = (values: Record<string, boolean | null>) => {
      return Object.values(values).filter((value) => {
        return !this.isEmpty(value);
      }).length;
    };
    return counter(this.value) + counter(this.persistedData);
  }

  get isFullfilled() {
    return !!(this.type === "battle"
      ? this.count >= (this.max || 1)
      : this.max && this.count >= this.max);
  }

  get rangeText() {
    if (this.min && this.max) {
      return this.min === this.max
        ? `${this.min}`
        : `min. ${this.min} - max. ${this.max}`;
    } else if (this.min) {
      return `min. ${this.min}`;
    } else if (this.max) {
      return `max. ${this.max}`;
    }
    return "";
  }

  isInactive(subjectId: string) {
    return (
      (this.readonly && !this.hasValue(subjectId)) ||
      (this.isFullfilled && !this.hasValue(subjectId))
    );
  }

  hasValue(subjectId: string) {
    return (
      !this.isEmpty(this.value[subjectId]) ||
      !this.isEmpty(this.persistedData[subjectId])
    );
  }

  isEmpty(value?: boolean | null) {
    return value === undefined || value === this.emptyValue;
  }

  get defaultModel() {
    return this.subjects.reduce<Record<string, unknown>>((acc, subject) => {
      acc[subject.id] = this.emptyValue;
      return acc;
    }, {});
  }

  @Watch("model", { deep: true, immediate: true })
  updateValue() {
    const model = merge({}, this.defaultModel, cloneDeep(this.model));
    if (!isEqual(model, this.value)) {
      this.value = model;
    }
  }

  @Watch("value", { deep: true, immediate: true })
  emitData() {
    if (!isEqual(this.model, this.value)) {
      this.$emit("update:modelValue", cloneDeep(this.value));
    }
  }

  refreshStylesForAnim(toggle = true) {
    ((this.$refs["subject"] || []) as Array<Vue>).forEach((comp) => {
      const el = comp.$el;
      if (!toggle) {
        el.style.width = "";
        el.style.left = "";
        el.style.top = "";
      } else {
        const bound = el.parentElement?.getBoundingClientRect();
        const rect = el.getBoundingClientRect();

        el.style.width = `${rect.width}px`;
        el.style.left = `${rect.left - (bound?.left || 0)}px`;
        el.style.top = `${rect.top - (bound?.top || 0)}px`;
      }
    });
  }

  @Watch("selectedSubject", { deep: true, immediate: true })
  updateSelection() {
    if (!isEqual(this.selectedSubject, this.selection)) {
      this.selection = this.selectedSubject;
    }
  }

  @Watch("selection", { deep: true, immediate: true })
  emitSelection() {
    if (!isEqual(this.selectedSubject, this.selection)) {
      this.$emit("update:selectedSubject", cloneDeep(this.selection));
    }
    this.refreshStylesForAnim(!!this.selection);
  }
}
