export type FetchConfig = RequestInit & {
  params?: Record<string, unknown>;
};

export type FetchResponse<T> = {
  data: T;
  status: Response["status"];
  headers: Response["headers"];
};

export class AbstractApi {
  static basePath: string | null = null;

  static async fetch(path: string, options?: FetchConfig) {
    const url = this.createUrl(path, options?.params, this.basePath);

    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status >= 400) {
      throw new Error(
        `${response.status} (${response.statusText || data.message})`
      );
    } else if (!data && (options?.method || "GET").toUpperCase() === "GET") {
      throw new Error("500 (Empty response)");
    }

    return {
      data,
      status: response.status,
      headers: response.headers,
    };
  }

  static createUrl(
    path: string,
    params?: Record<string, unknown>,
    basePath?: string | null
  ) {
    const queryParams = { ...params };
    const parsedPath = path.replace(/{(.*?)}/g, (substring, key: string) => {
      const param = params?.[key];
      if (param === undefined) {
        throw new Error();
      } else {
        delete queryParams[key];
      }
      return String(param);
    });
    const url = new URL(
      parsedPath,
      basePath
        ? basePath + (basePath[basePath.length - 1] === "/" ? "" : "/")
        : window.location.protocol + "//" + window.location.host + "/api/"
    );

    for (const key in queryParams) {
      url.searchParams.set(key, String(queryParams[key]));
    }

    return url.toString();
  }
}
