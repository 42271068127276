
import { mixins, Options, Watch } from "vue-property-decorator";
import { GetUserMixin } from "@rtl/ui";
import { VoteItemFetcher } from "@/mixins";
import {
  Advertising,
  Container,
  CtaButton,
  Headline,
  Page,
  Separator,
  TextContent,
} from "@/views/components";

@Options({
  components: {
    Advertising,
    Container,
    CtaButton,
    Headline,
    Page,
    Separator,
    TextContent,
  },
})
export default class VotePendingPage extends mixins(
  GetUserMixin,
  VoteItemFetcher
) {
  @Watch("vote", { immediate: true, deep: true })
  checkStatus() {
    if (this.vote) {
      if (!this.user) {
        this.$router.replace({
          name: "vote-index",
          params: this.vote.$linkParams,
        });
      } else if (this.vote.isRunning) {
        this.$router.replace({
          name: "vote-form",
          params: this.vote.$linkParams,
        });
      }
    }
  }
}
