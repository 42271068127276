import { VoteItem } from "@/api";

export class Vote {
  id!: VoteItem["id"];
  url!: VoteItem["url"];
  main!: {
    title: VoteItem["main_title"];
    text: VoteItem["main_text"];
    image: VoteItem["image"];
  };
  style!: VoteItem["style"];
  pending!: {
    title: VoteItem["pending_title"];
    text: VoteItem["pending_text"];
    advertising: VoteItem["advertising1"];
  };
  form!: {
    active: VoteItem["form_active"];
    action: VoteItem["form_action"];
    terms: VoteItem["form_terms"];
    adult_content: VoteItem["adult_content"];
    batchable: VoteItem["form_batchable"];
    modifiable: VoteItem["form_modifiable"];
    sms: VoteItem["sms"];
    questions: VoteItem["questions"];
    advertising: VoteItem["advertising2"];
  };
  createdAt!: Date;
  updatedAt!: Date;

  constructor(data: VoteItem) {
    this.$populate(data);
  }

  get isRunning() {
    return this.form.active;
  }

  $populate(data: VoteItem) {
    this.id = data.id;
    this.url = data.url;
    this.main = {
      title: data.main_title,
      text: data.main_text,
      image: data.image,
    };
    this.style = data.style;
    this.pending = {
      title: data.pending_title,
      text: data.pending_text,
      advertising: data.advertising1,
    };
    this.form = {
      active: data.form_active,
      action: data.form_action,
      terms: data.form_terms,
      adult_content: data.adult_content,
      batchable: data.form_batchable,
      modifiable: data.form_modifiable,
      sms: data.sms,
      questions: data.questions,
      advertising: data.advertising2,
    };
    this.createdAt = new Date(data.created_at * 1000);
    this.updatedAt = new Date(data.updated_at * 1000);
  }

  get $linkParams() {
    const [voteGroup, voteSlug] = this.url.split("/").slice(-2);
    return {
      voteGroup,
      voteSlug,
    };
  }

  $getStorageKey(userId: string) {
    return `vote_${this.id}_${userId}`;
  }
}
