
import { Options, Prop, Vue } from "vue-property-decorator";
import { Vote } from "@/models";
import VoteCard from "./VoteCard.vue";

@Options({
  components: {
    VoteCard,
  },
})
export default class VoteList extends Vue {
  @Prop({
    type: Array,
  })
  readonly votes: Array<Vote> | undefined;
}
