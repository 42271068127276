import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vote-subject__image-wrapper"
}
const _hoisted_2 = { class: "vote-subject__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_responsive_image = _resolveComponent("responsive-image")!

  return (_openBlock(), _createElementBlock("figure", {
    class: _normalizeClass(["vote-subject", { [`vote-subject--${_ctx.state}`]: _ctx.state }])
  }, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_responsive_image, {
            src: _ctx.image,
            class: "vote-subject__image"
          }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("figcaption", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.state), { class: "vote-subject__label-icon" })),
      _createTextVNode(" " + _toDisplayString(_ctx.name), 1)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}