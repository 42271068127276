import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vote_accept_consent = _resolveComponent("vote-accept-consent")!
  const _component_container = _resolveComponent("container")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, { is: "section" }, {
    default: _withCtx(() => [
      _createVNode(_component_container, { type: "section" }, {
        default: _withCtx(() => [
          _createVNode(_component_vote_accept_consent, { "success-url": { name: 'vote-pending' } })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}