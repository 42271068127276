
import { mixins, Options } from 'vue-property-decorator'

import AuthPageMixin from '../mixins/AuthPageMixin'

@Options({
  name: 'SocialProcess',
  components: {}
})

export default class SocialProcessPage extends mixins(AuthPageMixin) {
  mounted () {
    const queryString = window.location.search
    const responseObject = {
      type: 'social_response',
      query: queryString
    }
    if (window.opener) {
      window.opener.postMessage(JSON.stringify(responseObject))
      window.close()
    }
  }
}
