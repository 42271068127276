
import { Options, Vue } from "vue-property-decorator";
import OauthPage from "@rtl/ui/src/views/pages/SocialProcessPage.vue";

@Options({
  components: {
    OauthPage,
  },
})
export default class VoteOathProcessPage extends Vue {}
