import { registerPlugin } from "@capacitor/core";

export interface CallbackParam {
  url: string;
}

export interface PluginCallback {
  (deepLink: CallbackParam): void;
}

interface FbDynamicLinkPlugin {
  testPlugin(): Promise<{ alma: string }>;
  addListener(name: string, callback: PluginCallback): void;
}

const FbDynamicLink = registerPlugin<FbDynamicLinkPlugin>("FbDynamicLink");

export default FbDynamicLink;
