
import { mixins, Options } from "vue-property-decorator";
import { Action } from "vuex-class";

import {
  Checkbox,
  AuthDataEditMixin,
  AuthPageMixin,
  USER_ACTION,
  USER_NAMESPACE,
  UserResponse,
  AccountUpdateRequest,
} from "@rtl/ui";

@Options({
  name: "VoteAcceptConsent",
  components: {
    Checkbox,
  },
})
export default class VoteAcceptConsent extends mixins(
  AuthPageMixin,
  AuthDataEditMixin
) {
  @Action(USER_ACTION.UPDATE_ACCOUNT, { namespace: USER_NAMESPACE })
  updateAccountAction!: (params: AccountUpdateRequest) => Promise<UserResponse>;

  @Action(USER_ACTION.FORCED_LOGOUT, { namespace: USER_NAMESPACE })
  forcedLogoutAction!: () => void;

  isSocialDataUpdate = false;
  rtlhuConsent: boolean | undefined = undefined;

  created() {
    if (this.rtlhuConsent === undefined) {
      this.setSocialDataUpdate();
    }
  }

  async reset() {
    await this.forcedLogoutAction();
    this.rtlhuConsent = undefined;
    this.$router.push({ name: "vote-login" });
  }

  setSocialDataUpdate() {
    this.isSocialDataUpdate = true;
    this.rtlhuConsent = this.user?.data?.RTLHU_Consent;
  }

  isRtlHuConsentDisabled() {
    return this.needDataFill() && this.rtlhuConsent === true;
  }

  save() {
    this.clearFormErrors();

    if (this.isSocialDataUpdate) {
      this.validateRequiredBoolean(
        this.rtlhuConsent as boolean,
        "RTLHU_Consent",
        "Az adatkezelési tájékoztató és az általános szerződési feltételek elfogadása kötelező"
      );
    }

    if (!this.hasFormError()) {
      this.socialUpdate();
      this.$router.push(this.successUrl);
    }
    this.scrollUp();
  }

  async socialUpdate() {
    try {
      const registerData = {
        UID: this.user?.UID as string,
        profile: {
          firstName: this.user?.profile?.firstName,
          lastName: this.user?.profile?.lastName,
          gender: this.user?.profile?.gender,
          birthDay: this.user?.profile?.birthDay,
          birthMonth: this.user?.profile?.birthMonth,
          birthYear: this.user?.profile?.birthYear,
        },
        data: {
          RTLHU_Consent: this.rtlhuConsent,
          RTLHU_Newsletter: this.user?.data?.RTLHU_Newsletter,
          RTLMOST_Consent: this.user?.data?.RTLMOST_Consent,
          RTLMOST_Newsletter: this.user?.data?.RTLMOST_Newsletter,
        },
      };
      await this.updateAccountAction(registerData);
    } catch (e) {
      this.addFormError({
        field: "error",
        msg: "Hiba történt a regisztráció során. Kérjük próbáld meg újra!",
      });
      this.scrollUp();
    }
  }
}
