import { Module } from "vuex";
import {
  VoteApi,
  VoteCreateResponse,
  VoteItemResponse,
  VoteListResponse,
} from "@/api";
import {
  VOTE_ACTION,
  VOTE_GETTER,
  VOTE_MUTATION,
  VoteCreatePayload,
  VoteItemPayload,
  VoteListPayload,
  VoteState,
} from "./types";

export function createVoteModule<RootState>(
  namespaced = false
): Module<VoteState, RootState> {
  const pendingRequests: Record<string, Promise<unknown>> = {};

  return {
    namespaced,
    state() {
      return {
        voteItemResponses: {},
        voteListResponses: {},
      };
    },
    getters: {
      [VOTE_GETTER.API]() {
        return new VoteApi();
      },
    },
    actions: {
      async [VOTE_ACTION.FETCH_ITEM](
        { state, getters, commit },
        payload: VoteItemPayload
      ): Promise<VoteItemResponse> {
        const { cached, ...params } = payload;
        const cacheKey = JSON.stringify(params);
        const cache = cached ? state.voteItemResponses[cacheKey] : null;

        if (cache) {
          return cache;
        }

        const api = getters[VOTE_GETTER.API];
        const reqKey = VOTE_ACTION.FETCH_ITEM + ":" + cacheKey;

        let response;
        try {
          response = Object.hasOwnProperty.call(pendingRequests, reqKey)
            ? await pendingRequests[reqKey]
            : await (pendingRequests[reqKey] = api.fetchItem(params));
        } finally {
          delete pendingRequests[reqKey];
        }

        commit(VOTE_MUTATION.ITEM, { key: cacheKey, value: response });

        return state.voteItemResponses[cacheKey];
      },
      async [VOTE_ACTION.FETCH_LIST](
        { state, getters, commit },
        payload: VoteListPayload
      ): Promise<VoteListResponse> {
        const { cached, ...params } = payload;
        const cacheKey = JSON.stringify(params);
        const cache = cached ? state.voteListResponses[cacheKey] : null;

        if (cache) {
          return cache;
        }

        const api = getters[VOTE_GETTER.API];
        const reqKey = VOTE_ACTION.FETCH_LIST + ":" + cacheKey;

        let response;
        try {
          response = Object.hasOwnProperty.call(pendingRequests, reqKey)
            ? await pendingRequests[reqKey]
            : await (pendingRequests[reqKey] = api.fetchList(params));
        } finally {
          delete pendingRequests[reqKey];
        }

        commit(VOTE_MUTATION.LIST, { key: cacheKey, value: response });

        return state.voteListResponses[cacheKey];
      },
      async [VOTE_ACTION.CREATE_VOTE](
        { getters },
        payload: VoteCreatePayload
      ): Promise<VoteCreateResponse> {
        const api = getters[VOTE_GETTER.API];
        const response = await api.createVote(payload);

        return response;
      },
    },
    mutations: {
      [VOTE_MUTATION.ITEM](
        state,
        payload: { key: string; value: VoteItemResponse }
      ) {
        state.voteItemResponses[payload.key] = payload.value;
      },
      [VOTE_MUTATION.LIST](
        state,
        payload: { key: string; value: VoteListResponse }
      ) {
        state.voteListResponses[payload.key] = payload.value;
      },
    },
  };
}
