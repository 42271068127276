
import { mixins, Options, Watch } from "vue-property-decorator";
import { VoteListFetcher } from "@/mixins";
import {
  Container,
  CtaButton,
  Error,
  Headline,
  Page,
  RtlNav,
  Separator,
  TextContent,
  VoteList,
} from "@/views/components";

@Options({
  components: {
    Container,
    CtaButton,
    Error,
    Headline,
    Page,
    RtlNav,
    Separator,
    TextContent,
    VoteList,
  },
})
export default class VoteListPage extends mixins(VoteListFetcher) {
  @Watch("votes", { immediate: true, deep: true })
  checkVoteCount() {
    /* if (this.votes?.length === 1) {
      this.$router.replace({
        name: "vote-index",
        params: this.votes[0].$linkParams,
      });
    } */
  }
}
