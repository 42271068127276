
import { Options, Prop, Vue } from "vue-property-decorator";
import { Image } from "@/api";
import { ResponsiveImage } from "@/views/components";

@Options({
  components: {
    ResponsiveImage,
  },
})
export default class VoteHeader extends Vue {
  @Prop({
    type: String,
  })
  readonly logo: Image | undefined;

  @Prop({
    type: String,
  })
  readonly background: Image | undefined;

  @Prop({
    type: String,
  })
  readonly color: string | undefined;
}
