
import { Model, Options, Prop, Vue } from "vue-property-decorator";
import CtaButton from "./CtaButton.vue";

@Options({
  components: {
    CtaButton,
  },
})
export default class ButtonGroup extends Vue {
  @Model("modelValue", {})
  readonly model!: unknown;

  @Prop({
    type: String,
    required: true,
  })
  readonly name!: string;

  @Prop({
    type: Array,
    required: true,
  })
  readonly options!: Array<{
    label: string;
    value: unknown;
    theme: CtaButton["theme"];
  }>;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly readonly!: boolean;

  get availableOptions() {
    return this.options.filter((item) => {
      return this.model === null || this.model === item.value;
    });
  }

  reset(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.$emit("update:modelValue", null);
  }
}
