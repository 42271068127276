
import { Inject, Options, Prop, Vue } from "vue-property-decorator";
import { ArrowRight, MainLogo as RtlLogo } from "@rtl/ui";
import { Container } from "@/views/components";

export enum RtlNavPosition {
  FIX_TOP = "fix-top",
  FIX_BOTTOM = "fix-bottom",
  STICKY_TOP = "sticky-top",
  STICKY_BOTTOM = "sticky-bottom",
}

@Options({
  components: {
    ArrowRight,
    RtlLogo,
    Container,
  },
})
export default class RtlNav extends Vue {
  @Inject()
  rtlUrl!: string;

  @Prop({
    type: String,
  })
  readonly position: RtlNavPosition | undefined;

  isPinnedTop = false;
  isPinnedBottom = false;

  get isPinnable() {
    const pos = [RtlNavPosition.STICKY_TOP, RtlNavPosition.STICKY_BOTTOM];
    return !!this.position && pos.includes(this.position);
  }

  get observer() {
    return new IntersectionObserver(
      ([entry]) => {
        if (this.isPinnable) {
          const isPinned = entry.intersectionRatio < 1;
          this.isPinnedTop = isPinned && entry.boundingClientRect.top <= 1;
          this.isPinnedBottom = isPinned && entry.boundingClientRect.top > 1;
        }
      },
      { rootMargin: "-1px 0px", threshold: [0, 1] }
    );
  }

  mounted() {
    this.observer.observe(this.$el);
  }

  beforeUnmount() {
    this.observer.disconnect();
  }
}
