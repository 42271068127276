import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "error__icon" }
const _hoisted_2 = { class: "error__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_exclamation = _resolveComponent("exclamation")!
  const _component_close = _resolveComponent("close")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["error", { 'error--inline': _ctx.inline, 'error--vertical': _ctx.vertical }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_exclamation)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        (_ctx.closable)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "error__close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, [
              _createVNode(_component_close)
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}