import {
  VoteCreateRequest,
  VoteItemResponse,
  VoteItemRequest,
  VoteListResponse,
  VoteListRequest,
} from "@/api";

export type VoteItemPayload = VoteItemRequest & {
  cached?: boolean;
};

export type VoteListPayload = VoteListRequest & {
  cached?: boolean;
};

export type VoteCreatePayload = VoteCreateRequest;

export type VoteState = {
  voteItemResponses: Record<string, VoteItemResponse>;
  voteListResponses: Record<string, VoteListResponse>;
};

export enum VOTE_GETTER {
  API = "voteApi",
}

export enum VOTE_ACTION {
  FETCH_ITEM = "fetchVoteItem",
  FETCH_LIST = "fetchVoteList",
  CREATE_VOTE = "createVote",
}

export enum VOTE_MUTATION {
  ITEM = "voteItem",
  LIST = "voteList",
}

export const VOTE_NAMESPACE = "vote";
