import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "cta-button__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_transition_style = _resolveComponent("transition-style")!
  const _directive_visible = _resolveDirective("visible")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["cta-button", {
      [`cta-button--${_ctx.theme}`]: _ctx.theme && !_ctx.isCustomTheme,
      [`cta-button--${_ctx.size}`]: _ctx.size,
      'cta-button--selected': _ctx.selected,
      'cta-button--block': _ctx.block,
    }]),
    style: _normalizeStyle(_ctx.style),
    disabled: _ctx.loading
  }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])), [
        [_directive_visible, !_ctx.loading]
      ]),
      _createVNode(_component_transition_style, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_loader, {
                key: 0,
                class: "cta-button__loader"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["class", "style", "disabled"]))
}