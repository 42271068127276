import { reactive } from "vue";
import { Options, Vue, Watch } from "vue-property-decorator";
import { PAGE_ACTION, PAGE_NAMESPACE, PagePayload, PageResult } from "@rtl/ui";
import { Fetcher } from "@/utils";

@Options({})
export default class ContentPageFetcher extends Vue {
  get contentPage() {
    return this.contentPageFetcher.item as PageResult["data"] | null;
  }

  get contentPageFetcher() {
    const fetcher = new Fetcher((payload: PagePayload) => {
      return this.$store.dispatch(
        `${PAGE_NAMESPACE}/${PAGE_ACTION.ITEM}`,
        payload
      );
    });

    return reactive(fetcher);
  }

  get contentPagePayload() {
    return {
      url: "",
    };
  }

  @Watch("contentPagePayload", { deep: true })
  async fetchContentPage() {
    return await this.contentPageFetcher.run(this.contentPagePayload);
  }

  serverPrefetch() {
    return this.fetchContentPage();
  }

  beforeMount() {
    return this.fetchContentPage();
  }
}
