
import { Model, Options, Prop, Vue, Watch } from "vue-property-decorator";
import { cloneDeep, isEqual, merge } from "lodash";
import { Close, SliderLeft, SliderRight } from "@rtl/ui";
import { VoteAnswer, VoteSubject as VoteSubjectType } from "@/api";
import { Vote } from "@/models";
import { Container } from "@/views/components";
import VoteQuestion from "./VoteQuestion.vue";

@Options({
  components: {
    Close,
    Container,
    SliderLeft,
    SliderRight,
    VoteQuestion,
  },
})
export default class VoteForm extends Vue {
  @Model("modelValue", {
    type: Array,
    default: () => [],
  })
  readonly model!: Array<VoteAnswer>;

  @Model("questionIndex", {
    type: Number,
    default: 0,
  })
  readonly questionIndexModel!: number;

  @Model("selectedSubjectsData", {
    type: Object,
    default: () => ({}),
  })
  readonly selectedSubjectsModel!: Record<string, VoteSubjectType | null>;

  @Prop({
    type: Array,
    default: () => [],
  })
  readonly persistedData!: Array<VoteAnswer>;

  @Prop({
    type: Object,
    required: true,
  })
  readonly vote!: Vote;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly readonly!: boolean;

  value: Array<VoteAnswer> = [];
  formValue: Record<string, Record<string, boolean | null>> = {};
  persistedValue: Record<string, Record<string, boolean | null>> = {};
  selectedSubjects: Record<string, VoteSubjectType | null> = {};
  questionIndexValue = 0;

  get question() {
    return this.vote.form.questions[this.slideIndex || 0];
  }

  get slidesNum() {
    return this.vote.form.questions.length;
  }

  get slideIndex() {
    return this.questionIndexValue;
  }

  set slideIndex(value: number) {
    this.questionIndexValue = value % this.slidesNum;
    this.updateSlider();
  }

  get observer() {
    return new IntersectionObserver(
      (entries) => {
        const entry = entries.find((entry) => entry.isIntersecting);
        const parent = entry?.target.parentElement;
        if (parent) {
          const index = Array.from(parent.children).indexOf(entry.target);
          if (index >= 0 && this.slideIndex !== index) {
            this.questionIndexValue = index;
          }
        }
      },
      { root: this.slides[0].$el.parentElement, threshold: 0.1 }
    );
  }

  get slides() {
    return this.$refs.slides as Array<VoteQuestion>;
  }

  get defaultModel() {
    return this.vote.form.questions
      .map((question) =>
        question.subjects.map((subject) => ({
          question_id: question.id,
          subject_id: subject.id,
          subject_code: subject.code,
          value: null,
        }))
      )
      .flat();
  }

  cancelSelection() {
    const subject = this.selectedSubjects[this.question.id];
    if (subject) {
      this.formValue[this.question.id][subject.id] = null;
    }
    this.selectedSubjects[this.question.id] = null;
  }

  updateSlider() {
    const target = this.slides[this.slideIndex].$el;
    const parent = target.parentElement;
    parent.scrollTo({
      behavior: "smooth",
      left: target.offsetLeft,
    });
  }

  serializeValues(values: Array<VoteAnswer>) {
    return values.reduce<Record<string, Record<string, boolean | null>>>(
      (values, item) => {
        if (!values[item.question_id]) {
          values[item.question_id] = {};
        }
        values[item.question_id][item.subject_id] = item.value;
        return values;
      },
      {}
    );
  }

  @Watch("questionIndexModel", { immediate: true })
  updateQuestionIndex() {
    if (!isEqual(this.questionIndexModel, this.slideIndex)) {
      this.slideIndex = this.questionIndexModel;
    }
  }

  @Watch("slideIndex", { immediate: true })
  emitQuestionIndex() {
    if (!isEqual(this.questionIndexModel, this.slideIndex)) {
      this.$emit("update:questionIndex", this.slideIndex);
    }
  }

  @Watch("model", { deep: true, immediate: true })
  updateValue() {
    const model = merge([], this.defaultModel, cloneDeep(this.model));
    if (!isEqual(model, this.value)) {
      this.value = model;
    }
  }

  @Watch("value", { deep: true, immediate: true })
  emitData() {
    if (!isEqual(this.model, this.value)) {
      this.$emit("update:modelValue", cloneDeep(this.value));
    }

    const formValue = this.serializeValues(this.value);

    if (!isEqual(this.formValue, formValue)) {
      this.formValue = formValue;
    }
  }

  @Watch("formValue", { deep: true })
  handleFormValue() {
    const findSubjectCode = (subjectId: string) => {
      for (const question of this.vote.form.questions) {
        for (const subject of question.subjects) {
          if (subject.id === subjectId) {
            return subject.code;
          }
        }
      }
      return "";
    };
    const value: Array<VoteAnswer> = [];
    for (const question_id in this.formValue) {
      for (const subject_id in this.formValue[question_id]) {
        value.push({
          question_id,
          subject_id,
          subject_code: findSubjectCode(subject_id),
          value: this.formValue[question_id][subject_id],
        });
      }
    }

    if (!isEqual(this.value, value)) {
      this.value = value;
    }
  }

  @Watch("selectedSubjectsModel", { immediate: true, deep: true })
  updateSelectedSubjects() {
    if (!isEqual(this.selectedSubjectsModel, this.selectedSubjects)) {
      this.selectedSubjects = this.selectedSubjectsModel;
    }
  }

  @Watch("selectedSubjects", { immediate: true, deep: true })
  emitSelectedSubjects() {
    if (!isEqual(this.selectedSubjectsModel, this.selectedSubjects)) {
      this.$emit("update:selectedSubjectsData", this.selectedSubjects);
    }
  }

  @Watch("persistedData", { deep: true, immediate: true })
  updatePersistedValue() {
    this.persistedValue = this.serializeValues(this.persistedData);
  }

  mounted() {
    this.slides.forEach((slide) => {
      this.observer.observe(slide.$el);
    });
  }

  beforeUnmount() {
    this.observer.disconnect();
  }
}
