import { vShow as _vShow, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["true-value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_group = _resolveComponent("button-group")!
  const _component_vote_subject = _resolveComponent("vote-subject")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isBattle ? 'label' : 'div'), null, {
    default: _withCtx(() => [
      _createVNode(_component_vote_subject, {
        name: _ctx.subject.name,
        image: _ctx.subject.image,
        state: _ctx.getItemState(_ctx.model)
      }, {
        default: _withCtx(() => [
          (_ctx.isBattle)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 0,
                type: "checkbox",
                "true-value": _ctx.config.trueValue,
                "false-value": undefined,
                disabled: _ctx.inactive,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
              }, null, 8, _hoisted_1)), [
                [_vShow, false],
                [_vModelCheckbox, _ctx.model]
              ])
            : (_openBlock(), _createBlock(_component_button_group, {
                key: 1,
                name: `${_ctx.name}[${_ctx.subject.id}]`,
                options: [
          { label: 'Igen', value: _ctx.config.trueValue, theme: 'success' },
          { label: 'Nem', value: _ctx.config.falseValue, theme: 'danger' },
        ],
                togglable: true,
                readonly: _ctx.inactive,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event))
              }, null, 8, ["name", "options", "readonly", "modelValue"]))
        ]),
        _: 1
      }, 8, ["name", "image", "state"])
    ]),
    _: 1
  }))
}