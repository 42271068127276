
import { Options, Prop, Vue } from "vue-property-decorator";
import { Check, Cross } from "@rtl/ui";
import { ResponsiveImage } from "@/views/components";

@Options({
  components: {
    Yes: Check,
    No: Cross,
    ResponsiveImage,
  },
})
export default class VoteSubject extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly name!: string;

  @Prop({
    type: String,
  })
  readonly image: string | undefined;

  @Prop({
    type: String,
  })
  readonly state: "yes" | "no" | undefined;
}
