
import { Options, Vue } from "vue-property-decorator";
import { RouteLocationNormalized } from "vue-router";
import TransitionStyle from "./TransitionStyle.vue";

const DEFAULT_TRANSITION = "fade";
const DEFAULT_TRANSITION_MODE = "out-in";

@Options({
  components: {
    TransitionStyle,
  },
})
export default class TransitionPage extends Vue {
  prevHeight = "0";
  transitionName = DEFAULT_TRANSITION;
  transitionMode = DEFAULT_TRANSITION_MODE;

  mounted() {
    this.$router.afterEach(
      (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
        const hasContent = !!this.$el?.matches?.(":not(:empty)");

        let transitionName =
          to.meta.transitionName ??
          from.meta.transitionName ??
          DEFAULT_TRANSITION;
        let transitionMode = DEFAULT_TRANSITION_MODE;

        if (transitionName === "slide") {
          const toDepth = to.path.split("/").length;
          const fromDepth = from.path.split("/").length;
          transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
        }

        if (to.meta.transitionName === "zoom") {
          transitionMode = "in-out";
          document.body.style.overflow = "hidden";
        }

        if (from.meta.transitionName === "zoom") {
          transitionMode = "";
          document.body.style.overflow = "";
        }

        this.transitionMode = hasContent ? transitionMode : "";
        this.transitionName = transitionName;
      }
    );
  }

  beforeLeave(element: HTMLElement) {
    this.prevHeight = getComputedStyle(element).height;
  }

  enter(element: HTMLElement) {
    const { height } = getComputedStyle(element);

    element.style.height = this.prevHeight;

    setTimeout(() => {
      element.style.height = height;
    });
  }

  afterEnter(element: HTMLElement) {
    element.style.height = "";
  }
}
