export type FetcherCommand<T, R> = (payload: T) => Promise<R>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Fetcher<
  T,
  R extends { data: unknown },
  M extends new (data: R["data"]) => InstanceType<M>
> {
  command!: FetcherCommand<T, R>;
  model?: M | null = null;
  result?: R | null = null;
  error?: unknown | null = null;
  loading = false;
  loaded = false;

  constructor(command: FetcherCommand<T, R>, model?: M) {
    this.command = command;

    if (model) {
      this.model = model;
    }
  }

  get item(): InstanceType<M> | R["data"] | null {
    const data = this.result?.data;

    if (data) {
      return this.model ? new this.model(data) : data;
    }

    return null;
  }

  get list(): Array<InstanceType<M>> | R["data"] | null {
    const data = this.result?.data;

    if (data && Array.isArray(data)) {
      return data.map((item) => {
        return this.model ? new this.model(item) : item;
      });
    }

    return null;
  }

  async run(payload: T): Promise<R | null> {
    this.loading = true;
    this.error = null;

    try {
      this.result = await this.command(payload);
      return this.result;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
      this.loaded = true;
    }

    if (this.error) {
      throw this.error;
    }

    return null;
  }
}
