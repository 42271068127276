
import { mixins, Options, Prop } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'
import { Action } from 'vuex-class'

import { LoginRequest, UserResponse, FinalizeRegistrationRequest } from '../../gigya'
import { USER_ACTION, USER_NAMESPACE } from '../../store'
import Checkbox from '../components/auth/Checkbox.vue'
import PasswordInput from '../components/auth/PasswordInput.vue'
import TextInput from '../components/auth/TextInput.vue'
import Apple from '../components/icons/Apple.vue'
import Exclamation from '../components/icons/Exclamation.vue'
import Eye from '../components/icons/Eye.vue'
import FacebookFull from '../components/icons/FacebookFull.vue'
import Google from '../components/icons/Google.vue'
import SocialLinkAccount from '../components/SocialLinkAccount.vue'
import SocialRegFinish from '../components/SocialRegFinish.vue'
import AuthPageMixin from '../mixins/AuthPageMixin'

@Options({
  name: 'LoginPage',
  components: {
    Exclamation,
    Eye,
    FacebookFull,
    Google,
    Apple,
    TextInput,
    PasswordInput,
    Checkbox,
    SocialLinkAccount,
    SocialRegFinish
  }
})
export default class LoginPage extends mixins(AuthPageMixin) {
  @Action(USER_ACTION.LOGIN, { namespace: USER_NAMESPACE })
  loginUserAction!: (params: LoginRequest) => Promise<UserResponse>

  // todo: do we need this?
  @Action(USER_ACTION.FINALIZE_REGISTRATION, { namespace: USER_NAMESPACE })
  finalizeRegistrationAction!: (params: FinalizeRegistrationRequest) => Promise<UserResponse>

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'register' })
  })
  readonly signupUrl!: RouteLocationRaw

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'password_reset' })
  })
  readonly passwordUrl!: RouteLocationRaw

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'index' })
  })
  readonly personalUrl!: RouteLocationRaw

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showImage!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly socialDisabled!: boolean

  remember = false
  loginData: LoginRequest = {
    loginId: '',
    password: ''
  }

  mounted () {
    window.addEventListener('message', this.onPostMessage, false)
  }

  beforeUnmount () {
    window.removeEventListener('message', this.onPostMessage)
  }

  async login () {
    if (this.validateForm()) {
      if (this.remember) {
        this.loginData.sessionExpiration = 60 * 60 * 24 * 30 * 12 // 1 year
      }

      try {
        const loginResponse = await this.loginUserAction(this.loginData)
        if (loginResponse.errorCode === 206001) {
          await this.finalizeRegistrationAction({ regToken: loginResponse.regToken })
        }
        await this.accountInfoAction({ UID: loginResponse.UID })
        this.$router.push(this.needDataFill() ? this.personalUrl : this.successUrl)
        
      } catch (e) {
        this.addFormError({ field: 'error', msg: 'Helytelen belépési adatok' })
        this.scrollUp()
      }
    } else {
      this.scrollUp()
    }
  }

  validateForm (): boolean {
    this.clearFormErrors()
    this.validateEmail(this.loginData.loginId, 'loginId')
    this.validatePassword(this.loginData.password, 'password')
    return !this.hasFormError()
  }
}
