import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_responsive_image = _resolveComponent("responsive-image")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["vote-header", { 'vote-header--empty': !_ctx.color && !_ctx.background }])
  }, [
    _createElementVNode("div", {
      class: "vote-header__container",
      style: _normalizeStyle({ backgroundColor: _ctx.color })
    }, [
      (_ctx.background)
        ? (_openBlock(), _createBlock(_component_responsive_image, {
            key: 0,
            class: "vote-header__bg",
            src: _ctx.background
          }, null, 8, ["src"]))
        : _createCommentVNode("", true)
    ], 4),
    (_ctx.logo)
      ? (_openBlock(), _createBlock(_component_responsive_image, {
          key: 0,
          class: "vote-header__logo",
          src: _ctx.logo
        }, null, 8, ["src"]))
      : _createCommentVNode("", true)
  ], 2))
}