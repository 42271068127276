import FbDynamicLink, { CallbackParam } from "../plugin/FbDynamicLink";
import { Options, Vue } from "vue-property-decorator";

import { Capacitor } from "@capacitor/core";

@Options({
  name: "AppOAuthMixin",
})
export default class AppOAuthMixin extends Vue {
  processDeepLinkURL(deepLink: CallbackParam): void {
    if (
      deepLink.url &&
      (deepLink.url.includes("social-process") ||
        deepLink.url.includes("social-redirect"))
    ) {
      const search = deepLink.url.split("?")[1];
      window.location.href = window.location.href + "?" + search;
    }
  }

  registerDeeplinkListener() {
    FbDynamicLink.addListener("deepLinkOpen", this.processDeepLinkURL);
  }

  created() {
    if (Capacitor.isNativePlatform()) {
      this.registerDeeplinkListener();
    }
  }
}
