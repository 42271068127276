
import { mixins, Options, Prop } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'
import { Action } from 'vuex-class'

import { GigyaError, ResetPasswordRequest, ResetPasswordResponse } from '../../gigya'
import { ROUTES } from '../../router'
import { USER_ACTION, USER_NAMESPACE } from '../../store'
import PasswordInput from '../components/auth/PasswordInput.vue'
import TextInput from '../components/auth/TextInput.vue'
import Exclamation from '../components/icons/Exclamation.vue'
import Eye from '../components/icons/Eye.vue'
import AuthPageMixin from '../mixins/AuthPageMixin'

@Options({
  name: 'PasswordResetPage',
  components: {
    Exclamation,
    Eye,
    TextInput,
    PasswordInput
  }
})
export default class PasswordResetPage extends mixins(AuthPageMixin) {
  @Action(USER_ACTION.RESET_PASSWORD, { namespace: USER_NAMESPACE })
  resetPasswordAction!: (params: ResetPasswordRequest) => Promise<ResetPasswordResponse>

  @Prop({
    type: [String, Object],
    default: () => ({ name: 'login' })
  })
  readonly loginUrl!: RouteLocationRaw

  @Prop({
    type: Boolean,
    default: true
  })
  readonly showImage!: boolean

  resetData: ResetPasswordRequest = {
    loginId: '',
    newPassword: '',
    newPasswordConfirm: ''
  }

  resetEmailSent = false
  invalidTokenError = false

  mounted () {
    if ('pwrt' in this.$route.query) {
      this.resetData.passwordResetToken = this.$route.query.pwrt as string
    }
  }

  resetPassword () {
    if (this.validateForm()) {
      const params = this.correctPhaseParams()

      this.resetPasswordAction(params)
        .then(() => {
          if (!this.resetData.passwordResetToken) {
            this.resetEmailSent = true
          } else {
            this.$router.push({ name: ROUTES.LOGIN })
          }
        })
        .catch((e) => {
          if (e instanceof GigyaError && e.errorCode === 403025) {
            // nincs külön végpont a GIGYA-ban amivel csekkolni lehet az érvényességet, ezért post után kell
            this.addFormError({ field: 'error', msg: 'A jelszó változtatáshoz szükséges link érvénytelen, vagy már lejárt. A lenti gombra kattintva újra igényelheted jelszavad módosítását.' })
            this.invalidTokenError = true
          } else if (e instanceof GigyaError && e.errorCode === 403047) {
            // nem létező login id esetén nem dobjuk fel a hibát
            this.resetEmailSent = true
          } else {
            this.addFormError({ field: 'error', msg: 'Hiba történt a művelet során. Kérlek próbáld meg újra.' })
          }
          this.scrollUp()
        })
    } else {
      this.scrollUp()
    }
  }

  checkRequiredFields (): boolean {
    if (this.resetData.passwordResetToken) {
      return this.checkProperties(this.resetData as never, ['loginId'])
    } else {
      return this.checkProperties(this.resetData as never, ['newPassword', 'newPasswordConfirm', 'passwordResetToken'])
    }
  }

  validateForm (): boolean {
    this.clearFormErrors()

    if (!this.resetData.passwordResetToken) {
      this.validateEmail(this.resetData.loginId || '', 'loginId')
    } else {
      this.validatePassword(this.resetData.newPassword || '', 'newPassword', true)
      this.validatePasswordConfirm(this.resetData.newPasswordConfirm || '', this.resetData.newPassword || '', 'newPasswordConfirm')
    }

    return !this.hasFormError()
  }

  clearFormErrors () {
    this.formErrors = []
    this.invalidTokenError = false
    this.resetEmailSent = false
  }

  restart () {
    this.clearFormErrors()
    delete this.resetData.passwordResetToken
    this.$router.push({ name: ROUTES.PASSWORD_RESET })
  }

  correctPhaseParams (): ResetPasswordRequest {
    const params = Object.assign({}, this.resetData)

    if (params.passwordResetToken) {
      delete params.loginId
      delete params.newPasswordConfirm
    } else {
      delete params.newPassword
      delete params.newPasswordConfirm
    }

    return params
  }
}
