
import { Options, Inject, Vue } from "vue-property-decorator";
import VoteAcceptConsent from "../components/vote/VoteAcceptConsent.vue";
import { Container, Page } from "@/views/components";

@Options({
  components: {
    Container,
    VoteAcceptConsent,
    Page,
  },
})
export default class VoteAcceptConsentPage extends Vue {
  @Inject()
  platform!: string;
}
