
import { Options, Inject, Vue } from "vue-property-decorator";
import SignupPage from "@rtl/ui/src/views/pages/RegisterPage.vue";
import { Container, Page } from "@/views/components";

@Options({
  components: {
    Container,
    Page,
    SignupPage,
  },
})
export default class VoteSignupPage extends Vue {
  @Inject()
  platform!: string;
}
