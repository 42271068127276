import {
  RouteRecordRaw,
  createRouter as createVueRouter,
  createWebHistory,
} from "vue-router";

import VoteListPage from "../views/pages/VoteListPage.vue";
import VoteLayoutPage from "../views/pages/VoteLayoutPage.vue";
import VoteIndexPage from "../views/pages/VoteIndexPage.vue";
import VoteLoginPage from "../views/pages/VoteLoginPage.vue";
import VoteSignupPage from "../views/pages/VoteSignupPage.vue";
import VotePasswordPage from "../views/pages/VotePasswordPage.vue";
import VotePendingPage from "../views/pages/VotePendingPage.vue";
import VoteFormPage from "../views/pages/VoteFormPage.vue";
import VoteThanksPage from "../views/pages/VoteThanksPage.vue";
import VoteOauthProcessPage from "../views/pages/VoteOauthProcessPage.vue";
import VoteOauthPage from "../views/pages/VoteOauthPage.vue";
import VoteAcceptConsentPage from "../views/pages/VoteAcceptConsentPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "vote-list",
    component: VoteListPage,
  },
  {
    path: "/:voteGroup/:voteSlug",
    component: VoteLayoutPage,
    props: (route) => {
      return {
        group: route.params.voteGroup,
        slug: route.params.voteSlug,
      };
    },
    meta: {
      transitionName: "slide",
    },
    children: [
      {
        path: "",
        name: "vote-index",
        component: VoteIndexPage,
        props: (route) => {
          return {
            group: route.params.voteGroup,
            slug: route.params.voteSlug,
          };
        },
        meta: {
          rtlNav: false,
        },
      },
      {
        path: "belepes",
        name: "vote-login",
        component: VoteLoginPage,
      },
      {
        path: "regisztracio",
        name: "vote-signup",
        component: VoteSignupPage,
      },
      {
        path: "hozzaferes",
        name: "accept-consent-page",
        component: VoteAcceptConsentPage,
      },
      {
        path: "jelszo-emlekezteto",
        name: "vote-password",
        component: VotePasswordPage,
      },
      {
        path: "varoszoba",
        name: "vote-pending",
        component: VotePendingPage,
        props: (route) => {
          return {
            group: route.params.voteGroup,
            slug: route.params.voteSlug,
          };
        },
      },
      {
        path: "szavazas",
        name: "vote-form",
        component: VoteFormPage,
        props: (route) => {
          return {
            group: route.params.voteGroup,
            slug: route.params.voteSlug,
          };
        },
        meta: {
          rtlNav: false,
        },
      },
      {
        path: "vege",
        name: "vote-thanks",
        component: VoteThanksPage,
        props: (route) => {
          return {
            group: route.params.voteGroup,
            slug: route.params.voteSlug,
          };
        },
      },
    ],
  },
  {
    path: "/auth/social-process",
    name: "oauth-process",
    component: VoteOauthProcessPage,
  },
  {
    path: "/auth/social-redirect",
    name: "oauth",
    component: VoteOauthPage,
  },
];

export function createRouter() {
  return createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
}
