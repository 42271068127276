
import { Inject, mixins, Options, Watch } from "vue-property-decorator";
import {
  type SkeletonItem,
  htmlSerializer,
  ArrowRight,
  GetUserMixin,
  Renderer,
  // Storage,
} from "@rtl/ui";
import { ContentPageFetcher, VoteItemFetcher } from "@/mixins";
import {
  Advertising,
  Container,
  CtaButton,
  Headline,
  Page,
  Separator,
  VoteSubject,
} from "@/views/components";

@Options({
  components: {
    Advertising,
    ArrowRight,
    Container,
    CtaButton,
    Headline,
    Page,
    Renderer,
    Separator,
    VoteSubject,
  },
})
export default class VoteThanksPage extends mixins(
  ContentPageFetcher,
  GetUserMixin,
  VoteItemFetcher
) {
  @Inject()
  rtlUrl!: string;

  @Inject()
  platform!: string;

  @Watch("vote", { immediate: true, deep: true })
  checkStatus() {
    if (this.vote) {
      if (!this.user) {
        this.$router.replace({
          name: "vote-index",
          params: this.vote.$linkParams,
        });
      }
    }
  }

  /* get answer() {
    if (this.vote && this.user) {
      // eslint-disable-next-line no-console
      console.log(Storage.get(this.vote.$getStorageKey(this.user.UID)));

      return {
        label: "Todo: show data from cookies",
        image:
          "https://i1.wp.com/uk.blog.smartbuyglasses.com/files/2017/06/Kurt-cobain-throwback-sunglasses.jpg?fit=710%2C777&ssl=1",
      };
    }
    return null;
  } */

  get contentPagePayload() {
    return {
      url: "azeloszavazas",
    };
  }

  get contentHtml() {
    const elements = this.contentPage?.skeleton?.reduce(
      (acc: Array<SkeletonItem>, element) => {
        const [voteGroupSlug] = this.vote?.url.split("/").slice(-2) || [];
        if (element.parameters.dataVote === voteGroupSlug) {
          const el: SkeletonItem = { ...element };
          el.parameters.dataUtmSource = "szavazas.rtl.hu";
          el.parameters.dataUtmMedium = this.group;
          el.parameters.dataUtmCampaign = this.slug;
          acc.push(el);
        }
        return acc;
      },
      []
    );

    return elements?.length ? htmlSerializer(elements) : "";
  }
}
