import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vote-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vote_card = _resolveComponent("vote-card")!

  return (_ctx.votes?.length)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.votes, (vote, key) => {
          return (_openBlock(), _createBlock(_component_vote_card, {
            key: key,
            vote: vote,
            class: "vote-list__item"
          }, null, 8, ["vote"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}