import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_password_reset_page = _resolveComponent("password-reset-page")!
  const _component_container = _resolveComponent("container")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, { is: "section" }, {
    default: _withCtx(() => [
      _createVNode(_component_container, { type: "section" }, {
        default: _withCtx(() => [
          _createVNode(_component_password_reset_page, {
            "login-url": { name: 'vote-login' },
            "show-image": false
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}