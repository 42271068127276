
import { Options, Prop, Vue } from "vue-property-decorator";
import { Container, ResponsiveImage } from "@/views/components";

export enum TrackingAction {
  IMPRESSION = "impression",
  VIEWABILITY = "viewability",
  CLICK = "click",
}

export enum AdvertisingType {
  INLINE = "inline",
  OVERLAY = "overlay",
}

@Options({
  components: {
    Container,
    ResponsiveImage,
  },
})
export default class Advertising extends Vue {
  @Prop({
    type: String,
    default: "hirdetés",
  })
  readonly label!: string;

  @Prop({
    type: String,
    default: AdvertisingType.INLINE,
  })
  readonly type!: AdvertisingType;

  @Prop({
    type: Number,
    default: 5000,
  })
  readonly overlayDuration!: number;

  @Prop({
    type: String,
    required: true,
  })
  readonly code!: string;

  @Prop({
    type: String,
    required: true,
  })
  readonly image!: string;

  @Prop({
    type: String,
  })
  readonly link: string | undefined;

  isShow = true;

  get observer() {
    return new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.observer.disconnect();
          this.tracking(TrackingAction.VIEWABILITY);
        }
      },
      { threshold: [0.7] }
    );
  }

  tracking(action: TrackingAction) {
    this.$gtm?.trackEvent({
      event: "interaction",
      category: "vote-advertising",
      action,
      label: this.code,
    });
  }

  onClick() {
    this.tracking(TrackingAction.CLICK);
  }

  setAdHeight() {
    const imgRef = this.$refs["imageAd"] as Vue;
    const img = imgRef.$el;
    if (img) {
      img.style.display = "none";
      img.style.maxHeight = "none";

      const container = this.$refs["advertising"] as HTMLElement;
      const maxHeight: number = container.clientHeight;
      const padding = 32; // height of figcaption + container padding

      img.style.maxHeight = `${maxHeight - padding}px`;
      img.style.display = "block";
    }
  }

  mounted() {
    this.tracking(TrackingAction.IMPRESSION);
    this.observer.observe(this.$el);

    if (this.type === AdvertisingType.OVERLAY) {
      setTimeout(() => {
        this.isShow = false;
      }, this.overlayDuration);
    } else {
      this.setAdHeight();

      window.addEventListener("resize", this.setAdHeight);
    }
  }

  beforeUnmount() {
    this.observer.disconnect();
    window.removeEventListener("resize", this.setAdHeight);
  }
}
