
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class Page extends Vue {
  @Prop({
    type: String,
    default: "div",
  })
  readonly is!: string;
}
