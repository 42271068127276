
import { Options, Prop, Vue } from "vue-property-decorator";
import { vVisible } from "@/directives";
import { hexToHSL } from "@/utils";
import Loader from "./Loader.vue";
import TransitionStyle from "./TransitionStyle.vue";

export enum CtaButtonTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  DANGER = "danger",
  SUCCESS = "success",
}

export enum CtaButtonSize {
  NORMAL = "normal",
  SMALL = "small",
}

@Options({
  components: {
    Loader,
    TransitionStyle,
  },
  directives: {
    visible: vVisible,
  },
})
export default class CtaButton extends Vue {
  @Prop({
    type: String,
  })
  readonly is: string | undefined;

  @Prop({
    type: String,
    default: CtaButtonTheme.PRIMARY,
  })
  readonly theme!: CtaButtonTheme | `#${number}`;

  @Prop({
    type: String,
    default: CtaButtonSize.NORMAL,
  })
  readonly size!: CtaButtonSize;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly block!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly selected!: boolean;

  @Prop({
    type: Boolean,
  })
  readonly loading: boolean | undefined;

  get tag() {
    if (this.is) {
      return this.is;
    }
    if (this.$attrs.to) {
      return "router-link";
    }
    if (this.$attrs.href) {
      return "a";
    }

    return "button";
  }

  get isCustomTheme() {
    return this.theme.startsWith("#");
  }

  get style() {
    if (this.isCustomTheme) {
      const { h, s, l, a } = hexToHSL(this.theme);
      return {
        "--theme-h": `${h}deg`,
        "--theme-s": `${s}%`,
        "--theme-l": `${l}%`,
        "--theme-a": `${a}`,
      };
    }
    return null;
  }
}
