
import { Options, Prop, Vue } from "vue-property-decorator";
import { Close, Exclamation } from "@rtl/ui";
import Container from "./Container.vue";

@Options({
  components: {
    Close,
    Container,
    Exclamation,
  },
})
export default class Error extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  readonly closable!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly inline!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly vertical!: boolean;

  manualUpdate = false;
  show = true;

  close() {
    this.manualUpdate = true;
    this.show = false;
    this.$nextTick(() => {
      this.manualUpdate = false;
    });
    this.$emit("close");
  }

  beforeUpdate() {
    if (!this.manualUpdate) {
      this.show = true;
    }
  }
}
