
import { Options, Vue } from "vue-property-decorator";
import PasswordResetPage from "@rtl/ui/src/views/pages/PasswordResetPage.vue";
import { Container, Page } from "@/views/components";

@Options({
  components: {
    Container,
    PasswordResetPage,
    Page,
  },
})
export default class VotePasswordPage extends Vue {}
