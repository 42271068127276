
import { Options, Vue } from "vue-property-decorator";
import OauthPage from "@rtl/ui/src/views/pages/SocialRedirectPage.vue";

@Options({
  components: {
    OauthPage,
  },
})
export default class VoteOathPage extends Vue {}
