
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class BlockLink extends Vue {
  @Prop({
    type: String,
  })
  readonly is: string | undefined;

  get tag() {
    if (this.is) {
      return this.is;
    }
    if (this.$attrs.to) {
      return "router-link";
    }
    return "a";
  }
}
