
import { Options, Prop, Vue } from "vue-property-decorator";
import { Storage } from "@rtl/ui";
import { CtaButton, Headline } from "@/views/components";

@Options({
  components: {
    CtaButton,
    Headline,
  },
})
export default class AdultContent extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly active!: boolean;

  storageKey = "accept_adult";
  lifetime = 7 * 24 * 60 * 60 * 1000; // 7 days
  isShow = true;

  get isExpired() {
    if (typeof window !== "undefined") {
      const expire = Storage.get(this.storageKey);
      if (expire) {
        return Date.now() > expire + this.lifetime;
      } else {
        return true;
      }
    }
    return false;
  }

  get isActive() {
    return this.active && this.isShow && this.isExpired;
  }

  accept() {
    Storage.set(this.storageKey, Date.now());
    this.isShow = false;
  }
}
