import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.html)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.is), {
        key: 0,
        innerHTML: _ctx.html,
        class: "text-content"
      }, null, 8, ["innerHTML"]))
    : (_ctx.$slots.default)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.is), {
          key: 1,
          class: "text-content"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
}