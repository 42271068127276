import { ObjectDirective } from "@vue/runtime-core";

interface VVisibleElement extends HTMLElement {
  // _vod = vue original visibility
  _vod: string;
}

export const vVisible: ObjectDirective<VVisibleElement> = {
  beforeMount(el, { value }, { transition }) {
    el._vod = el.style.visibility === "hidden" ? "" : el.style.visibility;
    if (transition && value) {
      transition.beforeEnter(el);
    } else {
      setVisible(el, value);
    }
  },
  mounted(el, { value }, { transition }) {
    if (transition && value) {
      transition.enter(el);
    }
  },
  updated(el, { value, oldValue }, { transition }) {
    if (!value === !oldValue) {
      return;
    }
    if (transition) {
      if (value) {
        transition.beforeEnter(el);
        setVisible(el, true);
        transition.enter(el);
      } else {
        transition.leave(el, () => {
          setVisible(el, false);
        });
      }
    } else {
      setVisible(el, value);
    }
  },
  beforeUnmount(el, { value }) {
    setVisible(el, value);
  },
};

function setVisible(el: VVisibleElement, value: unknown): void {
  el.style.visibility = value ? el._vod : "hidden";
}

// SSR vnode transforms, only used when user includes client-oriented render
// function in SSR
export function initVVisibleForSSR() {
  vVisible.getSSRProps = ({ value }) => {
    if (!value) {
      return { style: { visibility: "hidden" } };
    }
  };
}
