import { createGtm, GtmPlugin } from "@gtm-support/vue-gtm";
import { createI18n, getGtmConfig } from "@rtl/ui";
import { createApp as createClientApp } from "vue";
import { createHead, VueHeadMixin } from "@unhead/vue";
import App from "./views/App.vue";
import { createRouter } from "./router";
import { createStore } from "./store";

export default async function createApp() {
  const DEV_MODE = process.env.NODE_ENV === "development";
  const router = createRouter();
  const store = createStore();
  const app = createClientApp(App);

  app.use(router);
  app.use(store);
  app.use(createHead()).mixin(VueHeadMixin);
  app.use(createI18n());

  store.commit("user/setUser", await store.getters["user/api"].getUser());

  const gtmId = store.getters.getEnv("GTM_ID");
  if (!DEV_MODE && gtmId) {
    app.use(createGtm(getGtmConfig(gtmId, router)));
  }

  return app;
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $store: ReturnType<typeof createStore>;
    $gtm?: GtmPlugin;
  }
}
