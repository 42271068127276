import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_transition_style = _resolveComponent("transition-style")!

  return (_openBlock(), _createBlock(_component_transition_style, {
    name: _ctx.transitionName,
    mode: _ctx.transitionMode,
    onBeforeLeave: _ctx.beforeLeave,
    onEnter: _ctx.enter,
    onAfterEnter: _ctx.afterEnter
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["name", "mode", "onBeforeLeave", "onEnter", "onAfterEnter"]))
}