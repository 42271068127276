import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "advertising__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_responsive_image = _resolveComponent("responsive-image")!
  const _component_container = _resolveComponent("container")!

  return (_ctx.isShow)
    ? (_openBlock(), _createElementBlock("figure", {
        key: 0,
        ref: "advertising",
        class: _normalizeClass(["advertising", { [`advertising--${_ctx.type}`]: _ctx.type }])
      }, [
        _createElementVNode("figcaption", _hoisted_1, _toDisplayString(_ctx.label), 1),
        _createVNode(_component_container, {
          class: "advertising__content",
          is: _ctx.link ? 'a' : 'div',
          href: _ctx.link,
          target: _ctx.link ? '_blank' : null,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.link ? _ctx.onClick($event) : null))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_responsive_image, {
              src: _ctx.image,
              class: "advertising__image",
              ref: "imageAd"
            }, null, 8, ["src"])
          ]),
          _: 1
        }, 8, ["is", "href", "target"])
      ], 2))
    : _createCommentVNode("", true)
}