import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vote-question__text vote-question__text--help"
}
const _hoisted_2 = { class: "vote-question__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headline = _resolveComponent("headline")!
  const _component_text_content = _resolveComponent("text-content")!
  const _component_container = _resolveComponent("container")!
  const _component_vote_answer = _resolveComponent("vote-answer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vote-question", { 'vote-question--has-selection': _ctx.selection }])
  }, [
    _createVNode(_component_container, { class: "vote-question__head" }, {
      default: _withCtx(() => [
        _createVNode(_component_headline, { size: "large" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_text_content, {
          html: _ctx.text,
          class: "vote-question__text"
        }, null, 8, ["html"]),
        (_ctx.rangeText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
              (_ctx.type === 'battle')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(" Válassz " + _toDisplayString(_ctx.rangeText) + " versenyzőt! ", 1)
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.type === 'yesno' || _ctx.type === 'balance')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(" Válaszolj " + _toDisplayString(_ctx.rangeText) + " versenyzőre! ", 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_container, { class: "vote-question__list" }, {
        default: _withCtx(() => [
          _createVNode(_TransitionGroup, { name: "vote-question__select-anim" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjects, (subject) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: subject.id
                }, [
                  (!_ctx.selection || _ctx.selection.id === subject.id)
                    ? (_openBlock(), _createBlock(_component_vote_answer, {
                        key: 0,
                        ref_for: true,
                        ref: "subject",
                        class: _normalizeClass(["vote-question__item", {
                'vote-question__item--selected': _ctx.selection?.id === subject.id,
                'vote-question__item--inactive': _ctx.isInactive(subject.id),
                'vote-question__item--readonly':
                  _ctx.readonly || !_ctx.isEmpty(_ctx.persistedData[subject.id]),
              }]),
                        style: _normalizeStyle(
                _ctx.selection?.id === subject.id
                  ? { width: '', top: '', left: '' }
                  : null
              ),
                        name: _ctx.name,
                        type: _ctx.type,
                        subject: subject,
                        inactive: _ctx.readonly || _ctx.isInactive(subject.id),
                        "model-value": 
                !_ctx.isEmpty(_ctx.persistedData[subject.id])
                  ? _ctx.persistedData[subject.id]
                  : _ctx.value[subject.id]
              ,
                        "onUpdate:modelValue": ($event: any) => (_ctx.value[subject.id] = $event),
                        onClick: ($event: any) => (_ctx.selectable && !_ctx.selection && (_ctx.selection = subject))
                      }, null, 8, ["class", "style", "name", "type", "subject", "inactive", "model-value", "onUpdate:modelValue", "onClick"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 2))
}