
import { Options, Prop, Vue } from "vue-property-decorator";

export enum HeadlineSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

@Options({})
export default class Headline extends Vue {
  @Prop({
    type: String,
    default: "h1",
  })
  readonly is!: string;

  @Prop({
    type: String,
    default: HeadlineSize.MEDIUM,
  })
  readonly size!: HeadlineSize;
}
