
import { Inject, mixins, Options } from "vue-property-decorator";
import { htmlSerializer, ArrowRight, GetUserMixin, Renderer } from "@rtl/ui";
import { ContentPageFetcher, VoteItemFetcher } from "@/mixins";
import {
  Container,
  CtaButton,
  Headline,
  Page,
  RtlNav,
  Separator,
  TextContent,
} from "@/views/components";

@Options({
  components: {
    ArrowRight,
    Container,
    CtaButton,
    Headline,
    Page,
    Renderer,
    RtlNav,
    Separator,
    TextContent,
  },
})
export default class VoteIndexPage extends mixins(
  ContentPageFetcher,
  GetUserMixin,
  VoteItemFetcher
) {
  @Inject()
  rtlUrl!: string;

  get contentHtml() {
    const element = this.contentPage?.skeleton?.filter((element) => {
      return element.componentName !== "banner";
    })[0];

    return element ? htmlSerializer([element]) : "";
  }
}
