import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vote-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_responsive_image = _resolveComponent("responsive-image")!
  const _component_headline = _resolveComponent("headline")!
  const _component_block_link = _resolveComponent("block-link")!

  return (_openBlock(), _createBlock(_component_block_link, {
    to: { name: 'vote-index', params: _ctx.vote.$linkParams }
  }, {
    default: _withCtx(() => [
      _createElementVNode("article", _hoisted_1, [
        _createVNode(_component_responsive_image, {
          src: _ctx.image,
          class: "vote-card__image"
        }, null, 8, ["src"]),
        _createVNode(_component_headline, { size: "medium" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.vote.main.title), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}