
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({})
export default class TextContent extends Vue {
  @Prop({
    type: String,
    default: "div",
  })
  readonly is!: string;

  @Prop({
    type: String,
  })
  readonly html: string | undefined;
}
