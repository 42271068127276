
import { Options, Vue } from 'vue-property-decorator'

@Options({
  name: 'SocialRedirect',
  components: {}
})

export default class SocialRedirectPage extends Vue {
  isRedirected = false;

  mounted () {
    const _url = window.location.href.split('?')
    const locationOrigin = this.$store.getters.getEnv('AUTH_REDIRECT') ?? window.location.origin
    const dynamicLink = this.$store.getters.getEnv('FIREBASE_DL')
    if (!dynamicLink) {
      alert('MISSING FIREBASE DYNAMIC LINK FROM ENV')
      throw new Error('MISSING VUE_APP_FIREBASE_DL FROM ENV')
    }
    const link = encodeURIComponent(locationOrigin + '/auth/social-process?' + _url[1])
    if (!this.isRedirected) {
      window.location.href = dynamicLink + '/?isi=1148979639&efr=1&apn=hu.telekomnewmedia.valovilag&ibi=hu.rtlmagyarorszag.rtl24&link=' + link
      this.isRedirected = true
      setTimeout(() => {
        window.close()
      }, 5000)
    }
  }
}
